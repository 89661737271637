import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Hypothekendarlehen = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Hypothekendarlehen" showCalc={false} />
            <Article>
                <p>
                    Für die Finanzierung einer Immobilie ist es in Österreich üblich, ein Hypothekendarlehen
                    aufzunehmen. So weit, so klar? Nein? Wir erklären dir, was dabei auf dich zukommt.
                </p>
                <hr />

                <h2>Darlehen und Kredit: Das ist der Unterschied</h2>
                <p>
                    Du fragst dich vielleicht, was genau ein Darlehen von einem Kredit unterscheidet. Obwohl die beiden
                    Begriffe im Alltag oft synonym gebraucht werden, gibt es einige Punkte, die es erlauben, diese
                    beiden Finanzierungsoptionen auseinanderzuhalten.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist ein Kredit?</h2>
                <p>
                    Unter einem Kredit versteht man ganz allgemein den Verleih einer Kapitalsumme, die in einem
                    bestimmten zeitlichen Rahmen und üblicherweise in monatlichen Raten zurückzuzahlen ist.
                    Zurückbezahlt wird allerdings nicht die verliehene Summe allein. Es fallen darüber hinaus{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    an. In juristischer Hinsicht ist zudem zentral, dass bereits durch den Abschluss des Kreditvertrags
                    ein{" "}
                    <Link to="/artikel/schulden/" target="_blank" rel="noreferrer noopener">
                        Schuldverhältnis
                    </Link>{" "}
                    zwischen Kreditnehmer*in und Kreditgeber*in entsteht – also noch bevor die Auszahlung des jeweiligen
                    Geldbetrages erfolgt ist.
                </p>
                <hr />

                <h2>Was ist ein Darlehen?</h2>
                <p>
                    Als eine der unzähligen verfügbaren Kreditarten zeichnet sich das Darlehen insbesondere durch seine
                    vergleichsweise lange Laufzeit und sein großes Kapitalvolumen aus. Gerade deswegen wird es meist zur{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Immobilienfinanzierung
                    </Link>{" "}
                    eingesetzt. Anders als der Kredit- erhält der Darlehensvertrag seine Gültigkeit erst durch die
                    Auszahlung der vereinbarten Summe. Davor besteht noch kein juristisch gültiges Schuldverhältnis. Von
                    anderen Kreditoptionen unterscheiden sich Darlehens ferner durch den Umstand, dass sie unentgeltlich
                    vergeben werden können – der*die Darlehensnehmer*in in diesem Fall also tatsächlich nur den
                    geliehenen Betrag zurückerstatten muss. Sind dennoch Zinsen zu bezahlen, so fallen diese meist
                    geringer aus als bei Krediten.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt zum Angebot
                    </a>
                </p>
                <hr />

                <h2>Und was ist ein Hypothekendarlehen?</h2>
                <p>
                    Im Falle eines <strong>Hypothekendarlehens</strong> (auch <strong>Hypothekardarlehen</strong>) hast
                    du es – nomen est omen – mit einem Darlehen zu tun, das an eine Hypothek gebunden ist. Diese Art des
                    Darlehens wird von österreichischen Banken bei{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Immobilienfinanzierungen
                    </Link>{" "}
                    präferiert, bietet sie doch eine vergleichsweise große Sicherheit: Die jeweilige Bank wird mit dem
                    sogenannten <strong>Grundpfandrecht</strong> in das Grundbuch eingetragen und darf im Falle der
                    Insolvenz des*der Kreditnehmer*in den ausständigen Kreditbetrag durch die Verpfändung der
                    betreffenden Immobilie einziehen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Welche Kriterien müssen erfüllt sein, damit ich ein Hypothekardarlehen erhalte?</h2>
                <p>
                    Üblicherweise gehen Banken davon aus, dass du 20-30 % der für den Kauf benötigten Summe in Form von
                    Eigenkapital in die Finanzierung einbringen kannst. Darüber hinaus wird der Wert der Immobilie
                    berechnet, die du mit dem Kredit erwerben willst – des sogenannten Beleihungsobjektes. Ihr Wert
                    bildet dann die Basis für die Kreditsumme (auch Beleihungshöhe). Natürlich hängt die Entscheidung
                    darüber, ob dir ein Kredit gewährt wird, auch von deiner{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    ab. Diese wird etwa anhand von Daten evaluiert, die der Kreditschutzverbandes (KSV) zu deinem
                    Zahlungsverhalten sammelt. Achte also darauf, Rechnung stets fristgerecht zu bezahlen und tilge
                    etwaige Konsumkredite. Darüber hinaus kann sich die Erstellung eines detaillierten
                    Finanzierungsplans positiv auf die Bereitschaft der Bank zur Kreditvergabe auswirken.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Beratung vereinbaren
                    </a>
                </p>
                <hr />

                <h2>Was bedeutet eine Hypothek für mich als Käufer*in?</h2>
                <p>
                    Wurde dir der Kredit gewährt, so wird der*die Kreditgeber*in in das Grundbuch eingetragen, wofür du
                    1,2 % des Pfandbetrags bezahlen musst. Nach der Rückerstattung des gesamten Kreditvolumens verfällt
                    die Grundschuld wieder. Willst du die Immobilie vor der vollständigen Rückzahlung des Kredites
                    verkaufen, so bleibt sie jedoch aufrecht, wodurch sich der Wert der Immobilie entsprechend
                    verringert. Bietet dir dein Kreditvertrag die Möglichkeit von Sondertilgungen – also
                    außerordentlichen Teilrückzahlungen –, so kannst du den Kredit auch vorzeitig abbezahlen – beachte
                    hierbei aber unbedingt die jeweiligen Gebühren.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Welche Nebenkosten sind zu erwarten?</h2>
                <p>
                    Durchschnittlich betragen die{" "}
                    <Link to="/artikel/kaufnebenkosten/" target="_blank" rel="noreferrer noopener">
                        Kaufnebenkosten
                    </Link>{" "}
                    beim Immobilienerwerb zwischen 10 und 12 % der Kaufpreises. Neben der bereits genannten Gebühr für
                    die Eintragung ins Grundbuch fallen u.a. noch Grunderwerb- und und Mehrwertsteuer, eine
                    Makler*innenprovision sowie Kosten für die Erstellung des Kaufvertrages an.
                </p>
                <p>
                    Gerade die Nebenkosten sind ein Punkt bei dem du mit der richtigen Beratung Geld sparen kannst.
                    Finde also einen verlässlichen Experten und lasse dich am besten unverbindlich beraten, bevor du
                    weitere Schritte machst!
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"hypothekendarlehen"}></BreadcrumbList>
            <ArticleStructuredData page={"hypothekendarlehen"} heading={"Hypothekendarlehen"} />
        </Layout>
    );
};

export default Hypothekendarlehen;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.hypothekendarlehen", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
